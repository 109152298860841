import React, { useState } from 'react'

// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

import '../styles/index.css'
// import Play from '../assets/play.svg';

import { smoothScrollBackToTop } from '../utils/index.js'

import Bg4 from '../assets/bg4.svg';
import Bg5 from '../assets/bg5.svg';
import Bg6 from '../assets/bg6.svg';

import Player from "../components/player"
import ImageFadeIn from "react-image-fade-in";

const IndexPage = () => {

  const [show, setShow] = useState(-1);

  return (
    <>
      <Layout show={show}>
        <SEO title="Home" description="BNY Mellon’s Future FirstSM Forum brings together ESG industry experts to discuss the innovations that are helping companies have a positive impact on people, communities and the planet while also driving long-term value."/>

            <div className="back-bg3 d-block" />
            <div className="back-bg2 d-block" />

            <div id="main" className="container-fluid ">
              <div className="container">
     
                <div className="mb-5 d-none d-lg-block" />

                <div className="row pt-5 pb-0 pb-sm-5">
                  <div className="col-lg-6">
                    <h1 className="publico-text-web display-4 mb-4 color-blue word-wrap-break" dangerouslySetInnerHTML={{__html: `Powering a<br>New Era of ESG` }} />
                    
                    <p className="h5 mb-4 color-blue">{`MAY 12, 2021 | A VIRTUAL EVENT`}</p>

                    <p className="h5-small mb-4 color-blue">{`If there had been any doubts about the importance of environmental, social and governance (ESG) practices, they have been laid to rest in the last year, as a global pandemic had devastating effects on societies and economies. Still, key questions remain about how companies can address ESG issues effectively.`}</p>

                    <p className="h5-small mb-2 mb-sm-2 color-blue">BNY Mellon’s <span className="superitalic">Future First<sup>SM</sup></span> Forum brought together industry experts to discuss the innovations that are helping companies have a positive impact on people, communities and the planet while also driving long-term value. This virtual event featured an illuminating keynote by Mark Carney and a series of panels showcasing how to measure ESG impact, how to effect meaningful change, the role of market infrastructure in establishing guidelines and how to find alpha in the process.</p>

                    <a 
                    rel="noreferrer"
                      id="watch-intro" 
                      href="/pdf/F3-RecapArticle.PDF"
                      onClick={(e)=>{
                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'main',
                                  eventAction: 'click',
                                  eventLabel: 'RECAP ARTICLE'
                                }
                              );
                            }
                      }}
                      target="_blank" 
                      className="btn btn-darkteal btn-lg watch-intro medium"
                    >RECAP ARTICLE</a>
                  </div>
                  <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-end">
                    <ImageFadeIn 
                      className={`start-image`}
                      src={`../images/start.jpg`}
                      srcSet={`../images/start@2x.jpg 2x`}
                      alt={`Powering a New Era of ESG`}
                    />
                  </div>
                </div>

                <div className="main-bg mt-2 mt-lg-0">
                  <div className="main-bg-white" style={{backgroundImage: `url(${`../images/water.jpg`})` }} >

                    <div className="triangle-top-white d-none d-lg-block" >
                      <Bg4 />
                    </div>

                    <div>
                      <div className="square-bottom-white d-none d-lg-block">
                        <Bg5 />
                      </div>
                      <div className="square-bottom-white2 d-block d-lg-block">
                        <Bg6 />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row d-none">
                  <div className="col">
                    <p className="publico-text-web display-4 mb-3 color-blue">{`Latest Insights`}</p>
                    <p className="h5-small mb-4 color-blue">{`As a trusted steward of the financial system, BNY Mellon brings together experts from all areas of financial services to share the latest thinking and developments. Explore their insights here:`}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                     <div className="position-relative embed-container">

                      

                        <iframe 
                          className="vidyard_iframe" 
                          src="//play.vidyard.com/Mc8nyXBEy9xKMrWqyMqC9L.html?autoplay=1&loop=1&muted=1&title=0" 
                          frameBorder="0"
                          title="video"
                          allow="autoplay"
                          allowFullScreen
                        />


                      {/*
                        <iframe 
                          id="iframe"
                          src="https://player.vimeo.com/video/533174716?autoplay=1&loop=1&muted=1&title=0"
                          allow="autoplay"
                          allowFullScreen
                          title="video"
                          background={1}
                          width={ `100%` }
                          height={ `auto` }
                          frameBorder="0" 
                        >
                        </iframe>
                      */}
                      </div>
                    </div>
                </div>

 
                  <div className="d-flex justify-content-center position-relative">
                    <a 
                      id="back-to-top" 
                      href="/#" 
                      className="btn btn-darkteal btn-lg back-to-top medium mt-17 mb-17 pl-5 pr-5"
                      onClick={(e)=>{
                        e.preventDefault();
                        smoothScrollBackToTop();

                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'main',
                                  eventAction: 'click',
                                  eventLabel: 'totop'
                                }
                              );
                            }

                      }}
                    >Back to top</a>
                  </div>
              </div>
            </div>
      </Layout>

      <Player UUID={show} setShow={setShow}/>
    </>
  )
}

export default IndexPage